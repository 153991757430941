// Chakra imports
import { Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import avatar4 from "./../../../../assets/img/avatars/avatar4.png";
import ProfileBgImage from "./../../../../assets/img/ProfileBackground.png";
import React from "react";
import Header from "../components/header";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import AffiliatePartnerProductInfo from "../components/affiliatePartnerProductInfo";
import AffiliatePartnerProductPrices from "../components/affiliatePartnerProductPrices";
import EmailIntegrationInfo from "../components/EmailIntegrationInfo";
import ForskelIntegrationInfo from "../components/forskelIntegrationInfo";
import ArosForsikringIntegrationInfo from "../components/arosForsikringIntegrationInfo";
import { GET_AFFILIATEPARTNERPRODUCT, updateAffiliatePartnerProductInfoGql } from "./graphQL";
import AffiliatePartnerProductDocument from "../components/affiliatePartnerProductDocument";
import HyperIntegrationInfo from "../components/hyperIntegrationInfo";

function AffiliatePartnerProduct() {
  // Chakra color mode
  const toast = useToast();
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );

  const { affiliatePartnerProductId } = useParams();
  const [updateAffiliatePartnerProductInfo] = useMutation(updateAffiliatePartnerProductInfoGql);

  const { loading, error, data, refetch } = useQuery(GET_AFFILIATEPARTNERPRODUCT, {
    variables: { id: affiliatePartnerProductId },
  });

  if (loading) return <p></p>; //loading

  if (error) {
    return <p>Error getting stats from server (1)</p>; //TODO: make error component
  }

  if (!data) {
    return <p>Error getting stats from server (2)</p>;
  }

  function forceRerender() {
    refetch();
  }

  const updateData = (affiliatePartnerId, field, data) => {
    let inputData = {};

    inputData[field] = data;
    inputData.id = affiliatePartnerId;
    updateAffiliatePartnerProductInfo({ variables: { input: inputData } });

    toast({
      title: "Ændringen er blevet gemt.",
      description: "",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={data.affiliatePartnerProduct.name}
        description={"Produkt"}
        view={"info"}
        estateBrokerId={affiliatePartnerProductId}
      />
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)", xl: "repeat(4, 1fr)" }} gap="22px">
        <GridItem w="100%" colSpan={2} rowSpan={0}>
          <AffiliatePartnerProductInfo data={data} dataUpdate={updateData} />
        </GridItem>

        {data.affiliatePartnerProduct.integrationType === "EMAIL" && (
          <GridItem w="100%" colSpan={2} rowSpan={0}>
            <EmailIntegrationInfo data={data} dataUpdate={updateData} />
          </GridItem>
        )}

        {data.affiliatePartnerProduct.integrationType === "FORSKEL_EL" && (
          <GridItem w="100%" colSpan={2} rowSpan={0} h="100%">
            <ForskelIntegrationInfo data={data} dataUpdate={updateData} h="100%" />
          </GridItem>
        )}

        {data.affiliatePartnerProduct.integrationType === "HYPER" && (
          <GridItem w="100%" colSpan={2} rowSpan={0} h="100%">
            <HyperIntegrationInfo data={data} dataUpdate={updateData} h="100%" />
          </GridItem>
        )}

        {data.affiliatePartnerProduct.integrationType === "AROS_FORSIKRING" && (
          <GridItem w="100%" colSpan={2} rowSpan={0} h="100%">
            <ArosForsikringIntegrationInfo data={data} dataUpdate={updateData} h="100%" />
          </GridItem>
        )}

        <GridItem w="100%" colSpan={2} rowSpan={0}>
          <AffiliatePartnerProductDocument data={data.affiliatePartnerProduct} forceRerender={forceRerender} />
        </GridItem>

        <GridItem w="100%" colSpan={4} rowSpan={0}>
          <AffiliatePartnerProductPrices PartnerProductId={affiliatePartnerProductId} />
        </GridItem>
      </Grid>
    </Flex>
  );
}

/*
       <AffiliatePartnerInfo data={data} />
        <AffiliatePartnerAddress data={data}/>
        <AffiliatePartnerNotes data={data}/>
        
*/

export default AffiliatePartnerProduct;
